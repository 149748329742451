import api from './api'
import { setErrors } from "utils";

export const handlePowerBiGroups = async () => (await api.get(`/powerbi/groups`)).data

export const handlePowerBiReports = async id => (await api.get(`/powerbi/groups/${id}/reports`)).data

export const handlePowerBiReportAad = async values => (await api.post(`/powerbi/reports/aad`, values)).data

export const handleNewToken = async (values) => (await api.post(`/powerbi/reports/newtoken`, values)).data

export const handlePowerBiReportEmbed = async (values, setMessages) => {
    try {
      return (await api.post(`/powerbi/reports/embed`, values)).data;
    } catch (error) {
        setMessages({ messages: setErrors(error), alert: "alert-orange" });
    }
  }
export const handlePowerBiExportReport = async values => (await api.post(`/powerbi/reports/exportTo`, values, { responseType: 'blob' })).data

export const handlePowerBiReportCustom = async values => (await api.post(`/powerbi/reports/report-custom`, values)).data