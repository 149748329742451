import styled from 'styled-components'

export const MessagesContainer = styled.div`
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30dvw;
  height: 10dvh;
  z-index: 1000;
  
  @media (max-width: 700px) {
    width: 50dvw;
  }
`